import React from 'react'
import PropTypes from 'prop-types'
import { Link, IntlContextConsumer } from 'gatsby-plugin-intl'

const ProxiedLink = ({ className, children, to, isProxied, style, ...props }) => {
  const internal = /^\/(?!\/)/.test(to) && !isProxied
  return internal ? (
    <Link className={className} to={to} style={style} {...props}>
      {children}
    </Link>
  ) : (
    <IntlContextConsumer>
      {({ languageToPrefix, defaultLanguage, language }) => {
        const prefix = language !== defaultLanguage ? `/${languageToPrefix[language]}` : ''
        return (
          <a className={className} href={`${prefix}${to}`} style={style} {...props}>
            {children}
          </a>
        )
      }}
    </IntlContextConsumer>
  )
}

ProxiedLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isProxied: PropTypes.bool,
  style: PropTypes.object,
}

ProxiedLink.defaultProps = {
  className: '',
  isProxied: false,
  style: {},
}

export default ProxiedLink
