export const QUOTE_SLIDER_OPTIONS = {
  navContainer: '#testimonials-nav',
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  gutter: 64,
  autoplay: true,
  items: 1,
  autoplayHoverPause: false,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  responsive: {
    768: {
      gutter: 100,
    },
    1280: {
      gutter: 150,
    },
    1440: {
      gutter: 500, // fix next slide visible at big screens (>=1920px)
    },
  },
}

export const CASES_URL = {
  'AES Brasil': { href: '/portfolio/aes-vpp/', isProxied: false },
  FindHeli: { href: '/portfolio/findheli/', isProxied: false },
  Tapcart: { href: '/portfolio/tapcart/', isProxied: false },
  Lockitron: { href: '/portfolio/lockitron/', isProxied: false },
  Skyroam: { href: '/portfolio/skyroam/', isProxied: false },
  'Singularity University': { href: '/portfolio/singularity-university/', isProxied: false },
  'Your Product May Be Here': { href: '/contact/', isProxied: false },
  'Seu Produto Pode Estar Aqui': { href: '/contact/', isProxied: false },
  Camio: { href: '/portfolio/camio/', isProxied: false },
  'EQI Investimentos': { href: '/portfolio/eqi/', isProxied: false },
  ExMox: { href: '/portfolio/exmox/', isProxied: false },
  Woovit: { href: '/portfolio/woovit/', isProxied: false },
  Swfast: { href: '/portfolio/swfast/', isProxied: false },
  MercadoLibre: { href: '/portfolio/meli/', isProxied: false },
  Sodexo: { href: '/portfolio/sodexo/', isProxied: false },
  'Quiver Quantitative': { href: '/portfolio/quiverquantitative/', isProxied: false },
  Thaw: { href: '/portfolio/thaw/', isProxied: false },
  Bluebenx: { href: '/portfolio/bluebenx/', isProxied: false },
  'Lys Academy': { href: '/portfolio/lys/', isProxied: false },
  Move: { href: '/portfolio/move/', isProxied: false },
  Cresol: { href: '/portfolio/cresol/', isProxied: false },
  Raimana: { href: '/portfolio/raimana/', isProxied: false },
  Hyperspace: { href: '/portfolio/hyperspace/', isProxied: true },
}

// https://stackoverflow.com/a/2970667
export const camelCase = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const IMG_AS_HERO_CONTAINER_STYLES = {
  objectFit: 'cover',
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  minHeight: '100%',
}

export const IMG_AS_HERO_IMG_STYLES = {
  objectFit: 'cover',
  objectPosition: 'center center',
}
